<template>
  <MainLayout>
    <template v-slot:toolbar>
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{{pageTitle}} pedido de inserção</h1>
        <!--end::Title-->
        <!--end::Title-->
      </div>
      <!--begin::Actions-->
      <div class="d-flex align-items-center gap-2 gap-lg-3 position-relative">
        <div class="m-0">
          <router-link
            :to="{ name: 'insertion.orders.show', params: { id: insertionOrder.id } }"
            class="btn btn-sm btn-primary"
            v-if="insertionOrder"
          >
            Voltar
          </router-link>
        </div>
        <!--end::Filter menu-->
      </div>
      <!--end::Actions-->
     <!--end::Page title-->
    </template>

    <!-- CONTENT -->
    <!--begin::Products-->
    <div class="card card-flush">
      <!--begin::Card body-->
      <div class="card-body pt-0">
        <template>
          <form class="default-form pt-10" @submit.prevent="">
            <div class="mb-5">
              <LoaderOverlay
                size="big"
                color="color2"
                v-if="overlayLoader"
              />
              <label class="required form-label">Anunciante</label>
              <Select2
                class="default-style"
                :options="advertisersOptions"
                @input="selectAdvertiser"
                placeholder="Buscar anunciante"
                :selectedOption="selectedOption"
                :disabled="disabled"
                :filterable="false"
                @search="searchAdvertisers">
              </Select2>
              <p class="mt-2">
                Caso o cliente ainda não possua conta na plataforma, <router-link :to="{ name: 'advertisers.create' }" class="fw-bold" target="_blank">clique aqui</router-link>
              </p>
              <div
                class="mt-4 alert alert-danger d-flex gap-5" role="alert"
                v-if="!advertiserHasCompleteInfo && advertiser"
              >
                O cadastro de empresa deste anunciante está incompleto.<br/> Por favor, complete-o antes de criar o PI, pois o cadastro incompleto pode gerar alguns erros.
                <ImpersonateButton
                  v-if="advertiser.company"
                  :userId="advertiser.user_impersonate_id"
                  page="company"
                />
              </div>
            </div>
            <div class="d-flex flex-column flex-lg-row mb-5">
              <div class="col-12 col-lg-2 pe-lg-5">
                <label class="required form-label">Perfil de cobrança</label>
                <select class="form-select" v-model.trim="form.billing_profile" :disabled="disabled">
                  <option value="ADVERTISER">Anunciante</option>
                  <option value="COMPANY">Empresa</option>
                </select>
              </div>
              <div class="col-12 col-lg-3 pe-lg-5">
                <label class="required form-label">Valor Total do Negócio</label>
                <Money
                  class="form-control mb-3"
                  v-model.trim="form.total_business_amount"
                  v-bind="money"
                  :disabled="checkIfHasOrders"
                />
              </div>
              <div class="col-12 col-lg-3 pe-lg-5 mb-5">
                <label class="required form-label">Valor da PI</label>
                <Money
                  class="form-control mb-3"
                  v-model.trim="form.amount"
                  v-bind="money"
                  :disabled="checkIfHasOrders"
                />
              </div>
              <div class="col-12 col-lg-2 pe-lg-5 mb-5">
                <template v-if="1 == 2">
                  <div v-if="form.should_credit_amount_on_platform">
                    <label class="required form-label">Valor para plataforma</label>
                    <Money
                      class="form-control mb-3"
                      v-model.trim="form.amount_for_platform"
                      v-bind="money"
                      :disabled="checkIfHasOrders"
                    />
                  </div>
                </template>
                <div class="mt-4 form-check">
                  <input class="form-check-input" type="checkbox" v-model="form.should_credit_amount_on_platform" :disabled="checkIfHasOrders" id="should_credit_amount_on_platform">
                  <label class="form-check-label" for="should_credit_amount_on_platform">PI credita valor na plataforma?</label>
                </div>
              </div>
            </div>
            <div class="mb-5 d-flex flex-column flex-lg-row">
              <div class="col-12 col-lg-3 pe-lg-5 mb-5">
                <label class="required form-label">Forma de pagamento</label>
                <select class="form-select" v-model.trim="form.payment_method" :disabled="checkIfHasOrders">
                  <option value="BOLETO">Boleto</option>
                  <option value="DEPOSIT">Depósito</option>
                </select>
              </div>
              <div class="col-12 col-lg-3 pe-lg-5">
                <label class="required form-label">Tipo de venda</label>
                <select class="form-select" v-model.trim="form.type">
                  <option selected disabled value="">Selecione</option>
                  <option value="FIRST_PURCHASE">Primeira Compra</option>
                  <option value="RENEWAL">Renovação</option>
                </select>
              </div>
              <div class="col-12 col-lg-3 pe-lg-5">
                <label class="required form-label">Percentual de comissão</label>
                <select class="form-select" v-model.trim="form.commission">
                  <option selected disabled value="">Selecione</option>
                  <option value="0">Não se aplica</option>
                  <option value="10">10%</option>
                  <option value="15">15%</option>
                  <option value="20">20%</option>
                </select>
              </div>
              <div class="col-12 col-lg-3 pe-lg-5">
                <label class="form-label">Número de PO</label>
                <input type="text" class="form-control mb-3" v-model.trim="form.external_id" />
              </div>
            </div>
            <!--begin::Repeater-->
            <div id="kt_docs_repeater_basic" class="mb-6" v-if="!checkIfHasOrders">
              <h4>Parcelas</h4>
              <div class="" v-if="!isEditPage">
                <div class="col-12 col-lg-3 pe-lg-5" v-if="form.installments.length === 0">
                  <label class="required form-label">Número de parcelas</label>
                  <select class="form-select" @change="handleSelectInstallments" v-model="installmentsNumber" :disabled="form.amount === 0">
                    <option selected disabled value="">Selecione</option>
                    <option value="1">1 Parcela</option>
                    <option value="2">2 Parcelas</option>
                    <option value="3">3 Parcelas</option>
                    <option value="4">4 Parcelas</option>
                    <option value="5">5 Parcelas</option>
                    <option value="6">6 Parcelas</option>
                    <option value="7">7 Parcelas</option>
                    <option value="8">8 Parcelas</option>
                    <option value="9">9 Parcelas</option>
                    <option value="10">10 Parcelas</option>
                    <option value="11">11 Parcelas</option>
                    <option value="12">12 Parcelas</option>
                  </select>
                </div>
              </div>
              <div class="">
                <div
                  class="form-group row mb-4"
                  v-for="(installment, index) in form.installments"
                  :key="index"
                >
                  <div class="col-6 col-lg-2 mb-5">
                    <label class="required form-label">Valor da parcela</label>
                    <Money
                      class="form-control"
                      v-model.trim="form.installments[index].amount"
                      v-bind="money"
                    />
                  </div>
                  <div class="col-6 col-lg-2 mb-5">
                    <label class="required form-label">Prazo de pagamento</label>
                    <DateTimeInput
                      v-model.trim="form.installments[index].payment_expiration_date"
                      format="dd/MM/yyyy"
                      type="date"
                      theme="basic"
                      class="w-100"
                      :min="true"
                    />
                  </div>
                  <div class="col-6 col-lg-2 pe-lg-5">
                    <div class="" v-if="form.installments[index].should_issue_nfse">
                      <label class="required form-label">Data de emissão NF</label>
                      <DateTimeInput
                        v-model.trim="form.installments[index].nfse_issuance_date"
                        format="dd/MM/yyyy"
                        type="date"
                        theme="basic"
                        class="w-100"
                        :min="true"
                        :disabled="disabled"
                      />
                    </div>
                    <div class="mt-4 form-check">
                      <input class="form-check-input" type="checkbox" v-model="form.installments[index].should_issue_nfse" id="should_issue_nfse">
                      <label class="form-check-label" for="should_issue_nfse">Emitir NF</label>
                    </div>
                  </div>
                  <div class="col-6 col-lg-3 pe-lg-5">
                    <div class="" v-if="form.installments[index].should_issue_nfse">
                      <label class="form-label">Descrição da NF</label>
                      <textarea v-model="form.installments[index].nfse_description" class="form-control" rows="2"></textarea>
                    </div>
                  </div>
                  <div class="col-lg-1">
                    <a @click="removeInstallment(index)" class="btn btn-sm btn-light-danger mt-3 mt-md-8">
                      <i class="fa-solid fa-trash-can pe-0"></i>
                    </a>
                  </div>
                </div>
                <div class="alert bg-danger d-flex flex-column flex-sm-row w-100 p-5 mb-10 mt-5" v-if="!checkInstallmentsAmountSum">
                  <div class="d-flex flex-column text-light pe-0 pe-sm-10">
                    <p class="mb-0">A soma do valor das parcelas precisa ser igual ao valor do PI</p>
                  </div>
                </div>
                <div class="alert bg-danger d-flex flex-column flex-sm-row w-100 p-5 mb-10 mt-5" v-if="form.amount === 0">
                  <div class="d-flex flex-column text-light pe-0 pe-sm-10">
                    <p class="mb-0">Por favor, insira o valor do PI</p>
                  </div>
                </div>
                <!--begin::Form group-->
                <div class="form-group mt-5">
                  <a @click="addInstallment(0)" class="btn btn-light-primary">
                      <i class="fa-solid fa-plus"></i>
                      Adicionar
                  </a>
              </div>
              <!--end::Form group-->
              </div>
            </div> <!-- end::Repeater-->
            <!--begin::Repeater-->
            <div id="kt_docs_repeater_basic" class="mb-6">
                <h4>Produtos</h4>
                <!--begin::Form group-->
                <div class="form-group">
                  <div v-for="field in form.products" :key="field.id">
                      <div class="form-group row mb-4">
                          <div class="col-md-6 col-lg-2 mb-3 mb-lg-0">
                              <label class="form-label fw-semibold required">Tipo</label>
                              <select class="form-select" aria-label="Select example" v-model="field.type">
                                <option value="AMAZON_ADS">Amazon Ads</option>
                                <option value="GOOGLE_ADS">Google Ads</option>
                                <option value="PROGRAMATIC">Midia Programática</option>
                                <option value="MERCADO_LIVRE_ADS">Mercado Livre Ads</option>
                                <option value="META_ADS">Meta Ads</option>
                                <option value="NATIVE_ADS">Native Ads</option>
                                <option value="PUSH_NOTIFICATIONS">Push Notifications</option>
                              </select>
                          </div>
                          <div class="col-md-6 col-lg-1 mb-3 mb-lg-0">
                              <label class="form-label fw-semibold required">Compra</label>
                              <select class="form-select" aria-label="Select example" v-model="field.type_purchase">
                                <option value="CPM">CPM</option>
                                <option value="CPC">CPC</option>
                              </select>
                          </div>
                          <div class="col-md-6 col-lg-2 mb-3 mb-lg-0">
                            <label class="form-label required">Custo</label>
                            <Money
                              class="form-control"
                              v-model.trim="field.amount"
                              v-bind="money"
                            />
                          </div>
                          <div class="col-md-6 col-lg-2 mb-3 mb-lg-0">
                            <label class="form-label fw-semibold required">Volume</label>
                            <input type="text" class="form-control" v-model="field.volume" v-if="!field.variable_volume">
                            <div class="form-check form-check-custom form-check-solid mt-2">
                              <input class="form-check-input" type="checkbox" v-model="field.variable_volume" :id="`variable_volume-${field.id}`" />
                              <label class="form-check-label" :for="`variable_volume-${field.id}`">
                                Variável
                              </label>
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-2 mb-3 mb-lg-0" v-if="field.variable_volume">
                              <label class="form-label fw-semibold required">Valor bruto</label>
                              <Money
                                class="form-control"
                                v-model.trim="field.gross_amount"
                                v-bind="money"
                              />
                          </div>
                          <div class="col-md-6 col-lg-2 mb-3 mb-lg-0" v-if="field.variable_volume">
                              <label class="form-label fw-semibold required">Valor líquido</label>
                              <Money
                                class="form-control"
                                v-model.trim="field.net_amount"
                                v-bind="money"
                              />
                          </div>
                          <div class="col-lg-1">
                              <a @click="removeField(field.id)" class="btn btn-sm btn-light-danger mt-3 mt-md-8">
                                <i class="fa-solid fa-trash-can pe-0"></i>
                              </a>
                          </div>
                      </div>
                  </div>
                </div>
                <!--end::Form group-->

                <!--begin::Form group-->
                <div class="form-group mt-5">
                    <a @click="addField" class="btn btn-light-primary">
                        <i class="fa-solid fa-plus"></i>
                        Adicionar
                    </a>
                </div>
                <!--end::Form group-->
            </div>
            <!--end::Repeater-->
            <div class="mt-3 text-center">
              <Button
                class="btn btn-primary me-3"
                @click="submit"
                :loading="loader"
                :disabled="(!advertiserHasCompleteInfo && advertiser) || !isInstallmentsFormValid"
              >
                {{ isEditPage ? 'Salvar PI' : 'Criar PI' }}
              </Button>
              <router-link
                class="btn btn-danger"
                :to="{ name: 'insertion.orders.show', params: { id: insertionOrder.id } }"
                v-if="insertionOrder"
              >
                Voltar
              </router-link>
            </div>
          </form>
        </template>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Products-->
    <!-- END CONTENT -->
  </MainLayout>
</template>

<script>

import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import InsertionOrdersService from '@/modules/insertion-orders/services/insertion-orders-service'
import UsersService from '@/modules/users/services/users-service'
import { getMoneyFormat, parseErrorResponseToArray } from '@/utils'

import Button from '@/components/common/Button/Button'
import DateTimeInput from '@/components/common/Input/DateTimeInput'
import ImpersonateButton from '@/components/users/ImpersonateButton'
import LoaderOverlay from '@/components/common/Loader/LoaderOverlay'
import MainLayout from '@/components/layouts/MainLayout'
import { Money } from 'v-money'
import Select2 from '@/components/common/Select/Select2'

export default {
  components: {
    Button,
    DateTimeInput,
    ImpersonateButton,
    LoaderOverlay,
    MainLayout,
    Money,
    Select2
  },
  metaInfo () {
    return {
      title: this.pageTitle
    }
  },
  data () {
    return {
      advertisers: [],
      advertiser: '',
      fieldCount: 1,
      form: {
        advertiser_id: '',
        amount: 0,
        amount_for_platform: 0,
        billing_profile: '',
        commission: '',
        external_id: '',
        installments: [],
        payment_method: '',
        products: [],
        should_credit_amount_on_platform: false,
        total_business_amount: '',
        type: ''
      },
      installmentsNumber: '',
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      insertionOrder: null,
      loader: false,
      overlayLoader: false,
      selectedOption: '',
      throttleSearch: null,
      users: []
    }
  },
  async created () {
    if (this.isEditPage) {
      await this.getInsertionOrder()
      this.selectAdvertiser()
    }
    this.getUsers()
  },
  watch: {
    'form.advertiser_id': function (newVal) {
      this.updateCurrencySettings(newVal)
    }
  },
  computed: {
    advertiserHasCompleteInfo () {
      if (!this.advertiser) {
        return false
      }

      // eslint-disable-next-line camelcase
      const { name, fantasy_name, address, document } = this.advertiser.company
      // eslint-disable-next-line camelcase
      if (!name || !fantasy_name || !address || !document) {
        return false
      }

      return true
    },
    /**
     * Check if IO has orders
    */
    checkIfHasOrders () {
      return this.insertionOrder?.orders.data.length > 0
    },
    /**
     * Check if installments amount sum is right
    */
    checkInstallmentsAmountSum () {
      let sum = 0
      this.form.installments.forEach(item => {
        sum += item.amount
      })

      return sum === this.form.amount
    },
    disabled () {
      if (this.isEditPage) {
        if (this.insertionOrder && this.insertionOrder.order && this.insertionOrder.order.nfse_status === 'ISSUED') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    /**
     * Check if is edit page
     */
    isEditPage () {
      return this.$route.name === 'insertion.orders.edit'
    },
    /**
     * Check if installments form is valid
    */
    isInstallmentsFormValid () {
      return this.checkInstallmentsAmountSum
    },
    /**
     * Get page title
     */
    pageTitle () {
      return this.$route.name === 'insertion.orders.create' ? 'Criar' : 'Editar'
    },
    advertisersOptions () {
      const processedIds = new Set()
      return this.advertisers
        .filter(advertiser => !processedIds.has(advertiser.id) && processedIds.add(advertiser.id))
        .map(advertiser => ({ label: `${advertiser.name}`, code: advertiser.id }))
    }
  },
  methods: {
    /**
     * add field - form repeater
     */
    addField () {
      this.fieldCount++
      this.form.products.push({ id: this.fieldCount, type: '', type_purchase: '', volume: '', variable_volume: false, amount: '', gross_amount: '', net_amount: '' })
    },
    /**
     * Add installment to installments array
    */
    addInstallment (amount = 0) {
      this.form.installments.push({
        amount: amount,
        nfse_description: '',
        nfse_issuance_date: '',
        payment_expiration_date: '',
        should_issue_nfse: true
      })
    },
    /**
     * Get insertion order
     */
    async getInsertionOrder () {
      this.overlayLoader = true
      try {
        const insertionOrder = await InsertionOrdersService.getInsertionOrder(this.$route.params.id)
        if (insertionOrder.status === 'FINISHED') return this.$router.push({ name: 'campaigns.show', params: { id: insertionOrder.id } })
        this.insertionOrder = insertionOrder
        this.setInsertionOrder(insertionOrder)
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
        this.$router.push('/')
      }
      this.overlayLoader = false
    },
    /**
     * Get advertisers for search
     */
    getAdvertisersForSearch (searchTerm, loading) {
      this.advertisers = []
      this.page = 1
      this.getAdvertisers(searchTerm, loading)
    },
    /**
     * Get advertisers
     */
    async getAdvertisers (searchTerm, loading) {
      try {
        const advertisers = await AdvertisersService.getAdvertisers({
          search: searchTerm,
          page: this.page
        })
        this.advertisers = [...this.advertisers, ...advertisers.data]
        loading(false)
      } catch (error) {
        this.errors = parseErrorResponseToArray(error)
      }
    },
    /**
     * Get users
    */
    async getUsers () {
      try {
        const users = await UsersService.getUsers({
          order_by: 'name',
          role: 'administrator',
          sort: 'ASC'
        })
        this.users = users.data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
    },
    /**
     * Handle when user select number of installments
    */
    handleSelectInstallments () {
      let amount = 0
      if (this.form.amount) {
        amount = this.form.amount / this.installmentsNumber
      }
      for (let i = 0; i < this.installmentsNumber; i++) {
        this.addInstallment(amount)
      }
      this.installmentsNumber = ''
    },
    /**
     * remove field - form repeater
     */
    removeField (id) {
      this.form.products = this.form.products.filter(obj => obj.id !== id)
    },
    /**
     * Remove installment
    */
    removeInstallment (index) {
      this.form.installments.splice(index, 1)
    },
    /**
     * Handle select advertiser
     */
    async selectAdvertiser (advertiser = null) {
      if (advertiser) {
        this.form.advertiser_id = advertiser.code
      }

      this.advertiser = await AdvertisersService.getAdvertiser(this.form.advertiser_id)
    },
    /**
     * Search advertisers
     */
    searchAdvertisers ({ searchTerm, loading }) {
      clearTimeout(this.throttleSearch)
      this.throttleSearch = setTimeout(() => {
        this.getAdvertisersForSearch(searchTerm, loading)
      }, 800)
    },
    /**
     * Set insertion order
     */
    setInsertionOrder (iOrder) {
      this.selectedOption = {
        label: iOrder.advertiser.name,
        code: iOrder.advertiser.id
      }
      this.form.advertiser_id = iOrder.advertiser.id
      this.form.billing_profile = iOrder.billing_profile
      this.form.amount = (iOrder.amount / 100)
      this.form.amount_for_platform = (iOrder.amount_for_platform / 100)
      this.form.should_credit_amount_on_platform = iOrder.should_credit_amount_on_platform
      this.form.payment_method = iOrder.payment_method
      this.form.type = iOrder.type
      this.form.commission = iOrder.commission
      this.form.external_id = iOrder.external_id
      // this.form.payment_expiration_date = iOrder.payment_expiration_date
      // this.form.nfse_issuance_date = iOrder.nfse_issuance_date
      // this.form.should_issue_nfse = iOrder.should_issue_nfse
      // this.form.nfse_description = iOrder.nfse_description
      this.form.total_business_amount = iOrder.total_business_amount / 100

      iOrder.products.forEach(product => {
        this.form.products.push({
          id: ++this.fieldCount,
          type: product.type,
          type_purchase: product.type_purchase,
          volume: product.volume || '',
          variable_volume: product.variable_volume,
          amount: (product.amount / 100),
          gross_amount: product.variable_volume ? (product.gross_amount / 100) : '',
          net_amount: product.variable_volume ? (product.net_amount / 100) : ''
        })
      })

      iOrder.installments.forEach(item => {
        this.form.installments.push({
          amount: item.amount / 100,
          nfse_description: item.nfse_description,
          nfse_issuance_date: item.nfse_issuance_date,
          payment_expiration_date: item.payment_expiration_date,
          should_issue_nfse: item.should_issue_nfse
        })
      })

      if (!iOrder.advertiser.order) this.selectDisabled = false
    },
    /**
     * Submit
     */
    async submit () {
      this.overlayLoader = true
      try {
        // Create copy of form that can be changed (object assign wont work for multi-layered objects)
        const form = JSON.parse(JSON.stringify(this.form))

        form.amount = parseInt(form.amount * 100)
        form.amount_for_platform = parseInt(form.amount_for_platform * 100)
        form.total_business_amount = parseInt(form.total_business_amount * 100)
        form.installments.forEach((item, index) => {
          form.installments[index].amount = parseInt(item.amount * 100)
        })

        let insertionOrder = null
        if (!this.insertionOrder) {
          insertionOrder = await InsertionOrdersService.createInsertionOrder(form)
        } else {
          insertionOrder = await InsertionOrdersService.updateInsertionOrder(this.insertionOrder.id, form)
        }

        this.$router.push({ name: 'insertion.orders.show', params: { id: insertionOrder.id } })
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.overlayLoader = false
    },
    /**
     * Update currency settings based on advertiser's currency
     */
    updateCurrencySettings (advertiserId) {
      const selectedAdvertiser = this.advertisers.find(advertiser => advertiser.id === advertiserId)

      if (!selectedAdvertiser) return
      this.money = getMoneyFormat(selectedAdvertiser.currency)
    }
  }
}
</script>
